*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 376px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1120px; } }

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1620px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 376px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.not-found {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px; }
  .not-found a {
    color: #F7941E; }

.about-content {
  text-align: center;
  margin: 0 0 15px 0; }
  @media (max-width: 991.98px) {
    .about-content {
      background-color: #f7f7f7; } }
  .about-content__segment {
    padding: 0 0 85px 0; }
    @media (max-width: 991.98px) {
      .about-content__segment {
        padding: 0 0 60px 0; } }
  .about-content__title {
    margin: 0 0 45px 0;
    color: #231f20;
    font-size: 42px;
    font-weight: 700; }
    @media (max-width: 991.98px) {
      .about-content__title {
        margin: 0 0 60px 0; } }
    .about-content__title span {
      color: #65c4e7; }
    .about-content__title--alt span {
      color: #add367; }
  .about-content__content {
    color: #515662; }
  .about-content__inner {
    padding: 85px 120px;
    background-color: #f7f7f7; }
    @media (max-width: 991.98px) {
      .about-content__inner {
        padding: 45px 20px; } }

.about-diagram {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#6b7388), to(#363a44));
  background-image: linear-gradient(180deg, #6b7388 0%, #363a44 100%);
  padding: 75px 0 75px 0;
  color: #fff; }
  .about-diagram h2 {
    color: #fff;
    margin: 0 0 45px 0;
    text-align: center;
    line-height: 1; }
  .about-diagram__segment {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0 0 145px 0;
    position: relative;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .about-diagram__segment--1 {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    @media (max-width: 991.98px) {
      .about-diagram__segment {
        max-width: 370px;
        padding: 0;
        margin: 0 auto; } }
    .about-diagram__segment:after {
      content: '';
      height: 120px;
      display: block;
      width: 4px;
      background-color: #f7941e;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      bottom: 20px; }
      @media (max-width: 991.98px) {
        .about-diagram__segment:after {
          display: none; } }
    .about-diagram__segment:last-child:after {
      display: none; }
  .about-diagram__seperator {
    width: 120px;
    height: 100%;
    position: relative; }
    @media (max-width: 1599.98px) {
      .about-diagram__seperator {
        display: none; } }
    .about-diagram__seperator::after {
      content: '';
      width: 100%;
      height: 4px;
      background-color: #f7941e;
      display: block;
      position: absolute;
      top: 117px;
      left: 0; }
    .about-diagram__seperator:last-child {
      display: none; }
  .about-diagram__item {
    font-size: 20px;
    font-weight: 700; }
    .about-diagram__item img {
      max-width: 100%;
      margin: 0 0 35px 0; }
      @media (max-width: 991.98px) {
        .about-diagram__item img {
          margin: 0 0 40px 0; } }
    .about-diagram__item__text, .about-diagram__item__title {
      margin: 0 0 20px 0; }
      @media (max-width: 991.98px) {
        .about-diagram__item__text, .about-diagram__item__title {
          margin: 0 0 40px 0; } }
    .about-diagram__item__title {
      font-size: 24px;
      line-height: 1; }
    .about-diagram__item--0 {
      max-width: 360px;
      margin: 0 auto; }
    .about-diagram__item--1 {
      max-width: 268px;
      width: 100%; }
      @media (max-width: 991.98px) {
        .about-diagram__item--1 {
          max-width: 100%; } }
    .about-diagram__item--2 {
      max-width: 360px;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .about-diagram__item--2 {
          max-width: 100%; } }
    .about-diagram__item--3 {
      max-width: 268px;
      width: 100%; }
      @media (max-width: 1599.98px) {
        .about-diagram__item--3 {
          padding: 0 10px 0 10px;
          max-width: 20%; } }
      @media (max-width: 991.98px) {
        .about-diagram__item--3 {
          max-width: 100%; } }
  .about-diagram__bottom {
    font-size: 32px;
    font-weight: 700; }

@media (max-width: 991.98px) {
  .alternating-content__single {
    margin: 0 0 16px 0; }
    .alternating-content__single:last-of-type {
      margin: 0; } }

.alternating-content__single__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (max-width: 1599.98px) {
  .alternating-content__single__content {
    margin: 30px 0; } }

@media (max-width: 767.98px) {
  .alternating-content__single__content {
    margin: 50px 0; } }

.alternating-content__single__content--homepage {
  background-color: #F8F8F8;
  padding: 80px 15px 80px 130px;
  margin-right: -16px;
  position: relative; }
  .alternating-content__single__content--homepage::after {
    content: '';
    background-color: #F8F8F8;
    display: block;
    position: absolute;
    left: calc(100% - 1px);
    top: 0;
    height: 100%;
    width: calc(100% / 6); }
  @media (max-width: 1599.98px) {
    .alternating-content__single__content--homepage {
      padding: 0;
      background-color: transparent;
      margin: 30px 0; }
      .alternating-content__single__content--homepage::after {
        display: none; } }
  @media (max-width: 767.98px) {
    .alternating-content__single__content--homepage {
      margin: 50px 0; } }

.alternating-content__single__content__title {
  margin: 0 0 35px 0; }
  .alternating-content__single__content__title span {
    color: #F7941E;
    line-height: 1; }
  .alternating-content__single__content__title--orange span {
    color: #F7941E; }
  .alternating-content__single__content__title--green span {
    color: #ADD367; }
  .alternating-content__single__content__title--blue span {
    color: #65C4E7; }

.alternating-content__single__content__link {
  margin: 30px 0 0 0;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  max-width: 260px;
  padding: 10px;
  border-radius: 30px;
  background-color: #F7941E;
  color: white;
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  text-align: center; }
  .alternating-content__single__content__link:hover {
    -webkit-transform: scale(0.98);
            transform: scale(0.98); }
  .alternating-content__single__content__link:active, .alternating-content__single__content__link:hover, .alternating-content__single__content__linkfocus {
    outline: none;
    color: white;
    text-decoration: none; }
  @media (max-width: 991.98px) {
    .alternating-content__single__content__link {
      margin: 40px 0 0 0; } }

.alternating-content__single__image {
  z-index: -2;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .alternating-content__single__image__inner {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: none; }
    @media (max-width: 991.98px) {
      .alternating-content__single__image__inner {
        display: block; } }

.banner {
  margin: 0 0 20px 0; }
  @media (max-width: 991.98px) {
    .banner .container {
      max-width: 100% !important;
      margin: 0;
      padding: 0; } }
  .banner__inner {
    height: 400px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media (max-width: 991.98px) {
      .banner__inner {
        height: 320px; } }
    @media (max-width: 375.98px) {
      .banner__inner {
        height: 200px; } }

.blog-banner {
  height: 800px;
  position: relative;
  margin: 0 0 72px 0; }
  @media (max-width: 991.98px) {
    .blog-banner__container {
      padding-left: 0;
      padding-right: 0;
      max-width: 100% !important;
      width: 100% !important;
      margin: 0; } }
  .blog-banner__text {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(173, 211, 103, 0.8);
    padding: 30px 0 30px 0; }
    @media (max-width: 991.98px) {
      .blog-banner__text {
        padding: 30px 35px 30px 35px; } }
    .blog-banner__text h1 {
      font-size: 42px;
      color: #fff;
      max-width: 992px;
      margin: 0 0 35px 0; }
      @media (max-width: 991.98px) {
        .blog-banner__text h1 {
          font-size: 32px; } }

.blog-item {
  width: 100%; }
  .blog-item__image {
    display: block;
    width: 100%;
    height: 325px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .blog-item__image {
        height: 260px; } }
    @media (max-width: 375.98px) {
      .blog-item__image {
        height: 180px; } }
    @media (max-width: 1599.98px) {
      .blog-item__image {
        display: none; } }
  .blog-item__mobile {
    display: none !important;
    max-width: 100%;
    height: auto;
    width: 100%; }
    @media (max-width: 1599.98px) {
      .blog-item__mobile {
        display: block !important; } }
  .blog-item__date {
    display: block;
    font-size: 13px;
    color: #F7941E;
    margin: 15px 0 5px 0; }
  .blog-item__title {
    font-size: 22px;
    display: block;
    font-weight: 700;
    color: #231f20; }
  .blog-item hr {
    border: none;
    width: 100%;
    height: 1px;
    display: block;
    background-color: #e7e7e7;
    margin: 10px 0; }
  .blog-item__excerpt {
    margin-top: 20px;
    color: #515662; }

.blogs__title-container {
  margin: 70px 0; }
  @media (max-width: 767.98px) {
    .blogs__title-container {
      margin: 60px 0; } }
  .blogs__title-container__title {
    margin: 0; }

.blogs__pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 100%; }
  @media (max-width: 767.98px) {
    .blogs__pagination {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding: 40px 0 0 0; } }
  .blogs__pagination--bottom {
    padding: 35px 0 45px 0; }
  .blogs__pagination__numbers {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 40px 0 0; }
    @media (max-width: 767.98px) {
      .blogs__pagination__numbers {
        margin: 0 20px; } }
    .blogs__pagination__numbers p {
      display: inline-block;
      margin: 0;
      font-size: 18px; }
      @media (max-width: 767.98px) {
        .blogs__pagination__numbers p {
          display: none; } }
    .blogs__pagination__numbers ul {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0; }
      .blogs__pagination__numbers ul li {
        display: inline-block; }
        .blogs__pagination__numbers ul li a, .blogs__pagination__numbers ul li span {
          margin: 0 0 0 5px;
          font-size: 18px; }
        .blogs__pagination__numbers ul li a {
          color: #231F20; }
          .blogs__pagination__numbers ul li a:hover {
            text-decoration: none; }
        .blogs__pagination__numbers ul li span {
          font-weight: 700;
          color: #F7941E; }
  .blogs__pagination__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -8px; }
    .blogs__pagination__buttons--mobile {
      display: none; }
      @media (max-width: 767.98px) {
        .blogs__pagination__buttons--mobile {
          display: block; } }
      @media (max-width: 767.98px) {
        .blogs__pagination__buttons--mobile .blogs__pagination__buttons__prev {
          display: block !important; } }
    .blogs__pagination__buttons__prev a, .blogs__pagination__buttons__next a {
      text-decoration: none; }
    .blogs__pagination__buttons__prev a::after, .blogs__pagination__buttons__next a::after {
      margin: 0 8px;
      content: '';
      display: block;
      font-size: 38px;
      color: #F7941E;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300; }
    @media (max-width: 767.98px) {
      .blogs__pagination__buttons__prev {
        display: none; } }
    .blogs__pagination__buttons__prev a::after {
      content: '\f060'; }
    .blogs__pagination__buttons__next a::after {
      content: "\f061"; }

.blogs__container {
  position: relative;
  z-index: 5;
  padding-top: 75px;
  padding-bottom: 0px; }
  .blogs__container .blog-item {
    padding-bottom: 75px; }
  .blogs__container:first-child {
    padding-top: 0; }
  .blogs__container:nth-child(2n):after {
    z-index: -1;
    background-color: rgba(173, 211, 103, 0.15);
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 100%; }

.single-blog {
  padding: 0 0 80px 0; }
  @media (max-width: 991.98px) {
    .single-blog {
      padding: 0 0 50px 0; } }
  .single-blog__back {
    font-size: 16px;
    font-weight: 700;
    background-color: #f7941e;
    width: 260px;
    max-width: 100%;
    padding: 15px 5px;
    line-height: 1;
    color: #fff;
    border-radius: 30px;
    text-align: center; }
    .single-blog__back a {
      color: #fff; }
      .single-blog__back a:hover {
        text-decoration: none;
        color: #fff; }
  .single-blog__content {
    font-size: 16px;
    color: #515662; }
    .single-blog__content p {
      margin: 0 0 30px 0; }
    .single-blog__content p:first-child {
      font-size: 22px; }
    .single-blog__content h3, .single-blog__content h4, .single-blog__content h5, .single-blog__content h6 {
      color: #f7941e;
      margin: 0 0 30px 0; }
    .single-blog__content h3 {
      font-size: 22px; }
    .single-blog__content h4 {
      font-size: 20px; }
    .single-blog__content h5 {
      font-size: 18px; }
    .single-blog__content h6 {
      font-size: 16px; }
  .single-blog__toolbar {
    border-bottom: 1px solid #add367;
    padding: 0 0 20px 0;
    margin: 0 0 40px 0; }
    .single-blog__toolbar__share {
      text-align: right; }
      @media (max-width: 991.98px) {
        .single-blog__toolbar__share {
          text-align: left;
          margin: 50px 0 0 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
      .single-blog__toolbar__share__title {
        color: #515662;
        font-weight: 400;
        margin: 0 23px 0 0;
        display: inline-block;
        vertical-align: middle; }
      .single-blog__toolbar__share__buttons {
        display: inline-block;
        vertical-align: middle; }
    .single-blog__toolbar__meta {
      margin: 0 0 12px 0;
      line-height: 1; }
      .single-blog__toolbar__meta:last-child {
        margin-bottom: 0; }
      .single-blog__toolbar__meta__title {
        display: inline-block;
        vertical-align: middle;
        color: #f7941e; }
      .single-blog__toolbar__meta__items {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        list-style-type: none; }
      .single-blog__toolbar__meta__item {
        display: inline-block;
        color: #515662; }
        .single-blog__toolbar__meta__item::after {
          content: ',';
          display: inline; }
        .single-blog__toolbar__meta__item:last-child:after {
          display: none; }

.app-video {
  margin: 0 0 90px 0; }
  @media (max-width: 991.98px) {
    .app-video {
      margin: 0; }
      .app-video .container {
        max-width: 100% !important;
        margin: 0;
        padding: 0; } }
  .app-video__inner {
    width: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(#6B7388), to(#363A44));
    background: linear-gradient(#6B7388, #363A44); }
  .app-video__content {
    padding: 50px 0;
    width: 100%;
    max-width: 540px;
    margin: 0 auto; }
    @media (max-width: 1599.98px) {
      .app-video__content {
        padding: 50px 35px; } }
    @media (max-width: 991.98px) {
      .app-video__content {
        background: -webkit-gradient(linear, left top, left bottom, from(#6B7388), to(#363A44));
        background: linear-gradient(#6B7388, #363A44);
        max-width: 100%; } }
    @media (max-width: 767.98px) {
      .app-video__content {
        padding: 50px 35px 70px 35px; } }
    .app-video__content__title {
      display: block;
      font-size: 32px;
      color: #ffffff;
      font-weight: 700;
      margin: 0; }
    .app-video__content__copy {
      color: #ffffff;
      margin: 30px 0 0 0; }
    .app-video__content__buttons {
      margin: 30px 0 0 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 767.98px) {
        .app-video__content__buttons {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start; } }
      .app-video__content__buttons__single {
        margin: 0 5px;
        -webkit-transition: -webkit-transform 250ms;
        transition: -webkit-transform 250ms;
        transition: transform 250ms;
        transition: transform 250ms, -webkit-transform 250ms;
        width: 50%;
        max-width: 260px; }
        @media (max-width: 767.98px) {
          .app-video__content__buttons__single {
            width: 100%; } }
        .app-video__content__buttons__single:last-of-type {
          width: 45%;
          max-width: 235px; }
          @media (max-width: 767.98px) {
            .app-video__content__buttons__single:last-of-type {
              width: 100%;
              margin: 20px 0 0 0; } }
        .app-video__content__buttons__single:hover {
          -webkit-transform: scale(0.98);
                  transform: scale(0.98); }
        .app-video__content__buttons__single img {
          width: 100%; }
  .app-video__video {
    width: 100%;
    height: 100%; }
    @media (max-width: 991.98px) {
      .app-video__video {
        min-height: 400px; } }
    @media (max-width: 767.98px) {
      .app-video__video {
        min-height: 320px; } }
    @media (max-width: 375.98px) {
      .app-video__video {
        min-height: 210px; } }

.selling-points {
  margin: 100px 0 16px 0; }
  @media (max-width: 991.98px) {
    .selling-points {
      margin: 100px 0 10px 0; }
      .selling-points .container {
        max-width: 100% !important;
        margin: 0;
        padding: 0; } }
  .selling-points__single {
    padding: 0 60px 30px 60px; }
    .selling-points__single--blue {
      background: -webkit-gradient(linear, left top, left bottom, from(#65C4E7), to(#336274));
      background: linear-gradient(#65C4E7, #336274); }
    .selling-points__single--green {
      background: -webkit-gradient(linear, left top, left bottom, from(#ADD367), to(#576A34));
      background: linear-gradient(#ADD367, #576A34); }
    .selling-points__single--orange {
      background: -webkit-gradient(linear, left top, left bottom, from(#F7941E), to(#7C4A0F));
      background: linear-gradient(#F7941E, #7C4A0F); }
    @media (max-width: 991.98px) {
      .selling-points__single {
        padding: 0 50px 30px 50px;
        margin: 0 0 80px 0; } }
    .selling-points__single__image {
      width: 100%;
      position: relative;
      margin-top: -100px; }
    .selling-points__single__title {
      display: block;
      font-size: 32px;
      color: #ffffff;
      font-weight: 700; }
    .selling-points__single__copy {
      color: #ffffff;
      margin: 50px 0 0 0; }
  .selling-points__single-container:last-of-type .selling-points__single {
    margin: 0 !important; }

.contact-form {
  padding: 0 0 130px 0;
  background-repeat: no-repeat;
  background-size: 980px;
  background-position-y: calc(100% + 240px);
  background-position-x: calc(100% + 65px); }
  @media (max-width: 991.98px) {
    .contact-form {
      padding: 0 0 60px 0; } }
  .contact-form__title {
    margin: 70px 0 35px 0; }
    @media (max-width: 991.98px) {
      .contact-form__title {
        margin: 60px 0 35px 0; } }
  .contact-form__form {
    margin: 50px 0 0 0; }
    .contact-form__form form {
      margin: -30px 0 0 0; }
    .contact-form__form input[type='text'], .contact-form__form input[type='email'], .contact-form__form input[type='textarea'], .contact-form__form textarea {
      width: 100%;
      padding: 15px;
      font-size: 0.875rem;
      border: solid 1px #707070;
      color: #F7941E;
      margin: 30px 0 0 0; }
      .contact-form__form input[type='text']::-webkit-input-placeholder, .contact-form__form input[type='email']::-webkit-input-placeholder, .contact-form__form input[type='textarea']::-webkit-input-placeholder, .contact-form__form textarea::-webkit-input-placeholder {
        color: #F7941E; }
      .contact-form__form input[type='text']::-moz-placeholder, .contact-form__form input[type='email']::-moz-placeholder, .contact-form__form input[type='textarea']::-moz-placeholder, .contact-form__form textarea::-moz-placeholder {
        color: #F7941E; }
      .contact-form__form input[type='text']:-ms-input-placeholder, .contact-form__form input[type='email']:-ms-input-placeholder, .contact-form__form input[type='textarea']:-ms-input-placeholder, .contact-form__form textarea:-ms-input-placeholder {
        color: #F7941E; }
      .contact-form__form input[type='text']::-ms-input-placeholder, .contact-form__form input[type='email']::-ms-input-placeholder, .contact-form__form input[type='textarea']::-ms-input-placeholder, .contact-form__form textarea::-ms-input-placeholder {
        color: #F7941E; }
      .contact-form__form input[type='text']::placeholder, .contact-form__form input[type='email']::placeholder, .contact-form__form input[type='textarea']::placeholder, .contact-form__form textarea::placeholder {
        color: #F7941E; }
      .contact-form__form input[type='text']:hover, .contact-form__form input[type='text']:active, .contact-form__form input[type='text']:focus, .contact-form__form input[type='email']:hover, .contact-form__form input[type='email']:active, .contact-form__form input[type='email']:focus, .contact-form__form input[type='textarea']:hover, .contact-form__form input[type='textarea']:active, .contact-form__form input[type='textarea']:focus, .contact-form__form textarea:hover, .contact-form__form textarea:active, .contact-form__form textarea:focus {
        outline: none; }
    .contact-form__form input[type='textarea'], .contact-form__form textarea {
      margin: 30px 0 0 0;
      min-height: 230px; }
      @media (max-width: 991.98px) {
        .contact-form__form input[type='textarea'], .contact-form__form textarea {
          min-height: 400px; } }
    .contact-form__form input[type='submit'] {
      display: block;
      margin: 30px 0 0 auto;
      font-size: 1rem;
      color: white;
      font-weight: 700;
      max-width: 260px;
      width: 100%;
      background-color: green;
      border-radius: 30px;
      background-color: #F7941E;
      padding: 10px;
      border: none;
      -webkit-transition: -webkit-transform 250ms;
      transition: -webkit-transform 250ms;
      transition: transform 250ms;
      transition: transform 250ms, -webkit-transform 250ms; }
      .contact-form__form input[type='submit']:hover {
        -webkit-transform: scale(0.98);
                transform: scale(0.98); }
      .contact-form__form input[type='submit']:hover, .contact-form__form input[type='submit']:active, .contact-form__form input[type='submit']:focus {
        outline: none; }
    .contact-form__form .wpcf7-not-valid-tip {
      font-weight: 700;
      margin: 10px 0 0 0; }
    .contact-form__form .wpcf7-response-output {
      background-color: #dc3232;
      margin: 0 !important;
      padding: 15px !important;
      border: none !important;
      color: #ffffff !important;
      font-weight: 700;
      border-radius: 10px; }

.faqs {
  margin: 0 0 105px 0; }
  .faqs__title {
    margin: 70px 0; }
    @media (max-width: 991.98px) {
      .faqs__title {
        margin: 60px 0; } }
  .faqs__single {
    margin: 0 0 40px 0; }
    .faqs__single:last-of-type {
      margin: 0; }
    .faqs__single--active .faqs__single__title {
      background-color: #ADD367; }
      .faqs__single--active .faqs__single__title i {
        -webkit-transform: translateY(-50%) rotate(90deg);
                transform: translateY(-50%) rotate(90deg); }
    .faqs__single__title {
      background-color: #F7941E;
      width: 100%;
      border-radius: 60px;
      display: block;
      padding: 20px 140px;
      position: relative; }
      @media (max-width: 1599.98px) {
        .faqs__single__title {
          padding: 20px 85px 20px 65px; } }
      @media (max-width: 991.98px) {
        .faqs__single__title {
          padding: 20px 85px 20px 45px; } }
      @media (max-width: 767.98px) {
        .faqs__single__title {
          padding: 30px 70px 30px 40px; } }
      .faqs__single__title__text {
        font-size: 22px;
        margin: 0;
        color: #ffffff;
        font-weight: 700; }
        @media (max-width: 991.98px) {
          .faqs__single__title__text {
            font-size: 16px; } }
      .faqs__single__title i {
        display: block;
        position: absolute;
        right: 40px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        -webkit-transition: -webkit-transform 250ms;
        transition: -webkit-transform 250ms;
        transition: transform 250ms;
        transition: transform 250ms, -webkit-transform 250ms;
        font-size: 38px;
        color: #ffffff; }
        @media (max-width: 767.98px) {
          .faqs__single__title i {
            right: 20px; } }
    .faqs__single__content {
      overflow: hidden;
      max-height: 0;
      height: auto;
      -webkit-transition: max-height 250ms;
      transition: max-height 250ms; }
      .faqs__single__content__inner {
        padding: 42px 0 0 0;
        margin: 0;
        overflow: hidden; }
      .faqs__single__content__video {
        width: 100%;
        height: 450px;
        display: block; }
        @media (max-width: 1599.98px) {
          .faqs__single__content__video {
            margin: 0 0 30px 0; } }
        @media (max-width: 991.98px) {
          .faqs__single__content__video {
            height: 400px; } }
        @media (max-width: 767.98px) {
          .faqs__single__content__video {
            height: 320px; } }
        @media (max-width: 375.98px) {
          .faqs__single__content__video {
            height: 170px; } }

.blog-slider {
  margin: 75px 0; }
  @media (max-width: 1599.98px) {
    .blog-slider {
      margin: 20px 0 70px 0; } }
  .blog-slider__title-container {
    margin: 0 0 30px 0; }
    @media (max-width: 767.98px) {
      .blog-slider__title-container {
        margin: 0 0 50px 0; } }
    .blog-slider__title-container__title {
      margin: 0; }
  .blog-slider__buttons {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: 0 20px 0 0; }
    @media (max-width: 991.98px) {
      .blog-slider__buttons {
        margin: 0; } }
    @media (max-width: 767.98px) {
      .blog-slider__buttons {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 50px 0 0 0; } }
    .blog-slider__buttons__button {
      font-size: 2.375rem;
      color: #F7941E;
      -webkit-transition: -webkit-transform 250ms;
      transition: -webkit-transform 250ms;
      transition: transform 250ms;
      transition: transform 250ms, -webkit-transform 250ms; }
      .blog-slider__buttons__button:hover {
        cursor: pointer;
        -webkit-transform: scale(0.95);
                transform: scale(0.95); }
      .blog-slider__buttons__button--right {
        margin: 0 0 0 15px; }
  .blog-slider__container {
    margin: 0 -8px; }
    .blog-slider__container .blog-item {
      margin: 0 8px;
      outline: none; }

.homepage-banner {
  width: 100%; }
  @media (max-width: 991.98px) {
    .homepage-banner .container {
      max-width: 100% !important;
      margin: 0;
      padding: 0; } }
  .homepage-banner__wrapper {
    width: 100%;
    height: 944px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; }
    @media (max-width: 767.98px) {
      .homepage-banner__wrapper {
        height: 712px; } }
  .homepage-banner__inner {
    position: absolute;
    top: 160px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 100%;
    max-width: 620px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .homepage-banner__inner {
        max-width: 100%;
        padding: 0 30px;
        top: 110px;
        max-width: 370px; } }
    .homepage-banner__inner__title {
      font-size: 82px;
      line-height: 100px;
      color: #fff;
      text-shadow: 0px 10px 10px #0000008c; }
      @media (max-width: 767.98px) {
        .homepage-banner__inner__title {
          font-size: 42px;
          line-height: 53px; } }
    .homepage-banner__inner__content {
      margin: 30px auto 0 auto; }
      @media (max-width: 767.98px) {
        .homepage-banner__inner__content {
          max-width: 300px; } }
      .homepage-banner__inner__content p {
        font-size: 24px;
        line-height: 35px;
        color: #fff; }
        @media (max-width: 767.98px) {
          .homepage-banner__inner__content p {
            font-size: 16px;
            line-height: 25px; } }
    .homepage-banner__inner__buttons {
      margin: 30px 0 0 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .homepage-banner__inner__buttons__single {
        margin: 0 5px;
        -webkit-transition: -webkit-transform 250ms;
        transition: -webkit-transform 250ms;
        transition: transform 250ms;
        transition: transform 250ms, -webkit-transform 250ms; }
        .homepage-banner__inner__buttons__single:hover {
          -webkit-transform: scale(0.98);
                  transform: scale(0.98); }
        .homepage-banner__inner__buttons__single img {
          width: 100%; }

.recipe-item {
  width: 100%; }
  .recipe-item__image {
    display: block;
    width: 100%;
    height: 240px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .recipe-item__image {
        height: 260px; } }
    @media (max-width: 375.98px) {
      .recipe-item__image {
        height: 180px; } }
  .recipe-item__title {
    font-size: 22px;
    display: block;
    font-weight: 700;
    margin: 15px 0 0 0;
    color: #ADD367;
    min-height: 66px; }
    @media (max-width: 767.98px) {
      .recipe-item__title {
        min-height: 0; } }
  .recipe-item hr {
    border: none;
    width: 100%;
    height: 1px;
    display: block;
    background-color: #7E818B;
    margin: 10px 0; }
  .recipe-item__excerpt {
    color: #ffffff; }
  .recipe-item__button {
    width: 100%;
    margin: 20px 0 0 0;
    font-size: 1rem;
    font-weight: 700;
    display: block;
    max-width: 260px;
    padding: 10px;
    border-radius: 30px;
    background-color: #F7941E;
    color: white;
    -webkit-transition: -webkit-transform 250ms;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    text-align: center; }
    .recipe-item__button:hover {
      -webkit-transform: scale(0.98);
              transform: scale(0.98); }
    .recipe-item__button:active, .recipe-item__button:hover, .recipe-item__buttonfocus {
      outline: none;
      color: white;
      text-decoration: none; }
    @media (max-width: 991.98px) {
      .recipe-item__button {
        max-width: 100%; } }

.recipe-slider {
  padding: 75px 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#6B7388), to(#363A44));
  background: linear-gradient(#6B7388, #363A44); }
  @media (max-width: 1599.98px) {
    .recipe-slider {
      padding: 50px 0 70px 0; } }
  .recipe-slider__title-container {
    margin: 0 0 20px 0; }
    @media (max-width: 767.98px) {
      .recipe-slider__title-container {
        margin: 0 0 50px 0; } }
    .recipe-slider__title-container__title {
      margin: 0;
      color: #ffffff; }
      .recipe-slider__title-container__title span {
        color: #F7941E; }
  .recipe-slider__buttons {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: 0 20px 0 0; }
    @media (max-width: 991.98px) {
      .recipe-slider__buttons {
        margin: 0; } }
    @media (max-width: 767.98px) {
      .recipe-slider__buttons {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 50px 0 0 0; } }
    .recipe-slider__buttons__button {
      font-size: 2.375rem;
      color: #ffffff;
      -webkit-transition: -webkit-transform 250ms;
      transition: -webkit-transform 250ms;
      transition: transform 250ms;
      transition: transform 250ms, -webkit-transform 250ms; }
      .recipe-slider__buttons__button:hover {
        cursor: pointer;
        -webkit-transform: scale(0.95);
                transform: scale(0.95); }
      .recipe-slider__buttons__button--right {
        margin: 0 0 0 15px; }
  .recipe-slider__container {
    margin: 0 -8px; }
    .recipe-slider__container .recipe-item {
      margin: 0 8px;
      outline: none; }

.page-content {
  padding: 60px 0 60px 0; }
  .page-content__title {
    line-height: 1;
    margin: 0 0 80px 0;
    color: #231f20; }
    .page-content__title h2 {
      color: #231f20; }
      .page-content__title h2 span {
        color: #f7941e; }
  .page-content__content {
    color: #515662;
    font-size: 16px; }
    .page-content__content p {
      margin: 0 0 30px 0; }
    .page-content__content * {
      color: #515662; }
  .page-content h3, .page-content h4, .page-content h5, .page-content h6 {
    color: #f7941e;
    margin: 0 0 30px 0; }
  .page-content h3 {
    font-size: 22px; }
  .page-content h4 {
    font-size: 20px; }
  .page-content h5 {
    font-size: 18px; }
  .page-content h6 {
    font-size: 16px; }

html {
  display: block;
  max-width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }
  html *::-moz-selection {
    background-color: #fff;
    color: #f77d0b; }
  html *::selection {
    background-color: #fff;
    color: #f77d0b; }
  html.overflow {
    overflow: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-image: url("/images/body-background.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center left;
  overflow-x: hidden; }
  @media (max-width: 1599.98px) {
    body {
      background-image: none; } }

@media (max-width: 375.98px) {
  .container {
    max-width: 316px; } }

p {
  font-size: 16px; }

h1 {
  font-size: 82px; }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 42px; } }

h2 {
  font-size: 42px; }
  h2 span {
    color: #F7941E;
    line-height: 1; }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 38px; } }

.app-overlay {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  opacity: 0.35; }

.app-footer {
  background-color: #231F20;
  border-top: solid 1px #707070; }
  .app-footer__social {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    list-style-type: none;
    padding: 0; }
    @media (max-width: 991.98px) {
      .app-footer__social {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
    @media (max-width: 991.98px) {
      .app-footer__social {
        margin: 0 0 35px 0; } }
    .app-footer__social__item {
      margin: 0 25px 0 0;
      padding: 0; }
      .app-footer__social__item:last-child {
        margin-right: 0; }
  .app-footer__stores {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style-type: none; }
    @media (max-width: 1599.98px) {
      .app-footer__stores {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
    @media (max-width: 991.98px) {
      .app-footer__stores {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin: 0 0 35px 0; } }
    .app-footer__stores__item {
      padding: 0;
      margin: 0; }
      @media (max-width: 991.98px) {
        .app-footer__stores__item {
          width: auto;
          max-width: calc(50% - 5px); }
          .app-footer__stores__item img {
            max-width: 100%; } }
      .app-footer__stores__item:first-child {
        margin-right: 20px; }
        @media (max-width: 991.98px) {
          .app-footer__stores__item:first-child {
            margin-right: 10px; } }
  .app-footer__top {
    padding: 20px 0; }
    .app-footer__top__row {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .app-footer__top__image {
      width: 100%; }
      @media (max-width: 991.98px) {
        .app-footer__top__image {
          max-width: 120px;
          margin: 0 0 35px 0; } }
    @media (max-width: 1599.98px) {
      .app-footer__top__menu {
        padding: 25px 0 10px 0; } }
    @media (max-width: 991.98px) {
      .app-footer__top__menu {
        padding: 0;
        margin: 0 0 25px 0; } }
    .app-footer__top__menu .menu {
      padding: 0;
      margin: 0;
      list-style: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      @media (max-width: 1599.98px) {
        .app-footer__top__menu .menu {
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
      @media (max-width: 991.98px) {
        .app-footer__top__menu .menu {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
      .app-footer__top__menu .menu .menu-item {
        display: inline-block;
        margin: 0 55px 0 0; }
        @media (max-width: 1599.98px) {
          .app-footer__top__menu .menu .menu-item {
            margin: 0; } }
        @media (max-width: 991.98px) {
          .app-footer__top__menu .menu .menu-item {
            display: block;
            width: 100%;
            margin: 0 0 15px 0;
            line-height: 1; } }
        .app-footer__top__menu .menu .menu-item:last-child {
          margin-right: 0; }
          @media (max-width: 991.98px) {
            .app-footer__top__menu .menu .menu-item:last-child {
              margin-bottom: 0; } }
        .app-footer__top__menu .menu .menu-item a {
          font-weight: 700;
          text-transform: uppercase;
          color: #ffffff; }
  .app-footer__bottom {
    border-top: solid 1px #6B7388;
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media (max-width: 991.98px) {
      .app-footer__bottom {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    .app-footer__bottom__copyright, .app-footer__bottom__tos {
      margin: 0;
      color: #ffffff;
      font-size: 0.8125rem; }
    .app-footer__bottom__copyright {
      margin: 0; }
      @media (max-width: 991.98px) {
        .app-footer__bottom__copyright {
          margin-bottom: 20px; } }
      .app-footer__bottom__copyright a {
        color: #ADD367;
        font-weight: uppercase; }
    .app-footer__bottom__tos {
      margin: 0;
      color: #ffffff; }
      .app-footer__bottom__tos:hover {
        color: #ffffff !important; }
      @media (max-width: 991.98px) {
        .app-footer__bottom__tos {
          padding: 0 0 25px 0; } }

.app-header {
  position: relative;
  z-index: 20; }
  @media (max-width: 991.98px) {
    .app-header {
      display: none; } }
  .app-header__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative; }
    .app-header__inner__logo {
      padding: 30px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0; }
      .app-header__inner__logo img {
        width: 190px;
        height: auto; }
    .app-header__inner__navigation {
      padding: 20px 0 0 250px;
      background-color: #fff;
      width: 100%;
      text-align: right; }
      .app-header__inner__navigation__social {
        display: inline-block;
        vertical-align: middle;
        margin: 0 30px 0 0;
        font-size: 0; }
        .app-header__inner__navigation__social__item {
          display: inline-block;
          margin: 0 20px 0 0;
          vertical-align: middle; }
          .app-header__inner__navigation__social__item:last-child {
            margin: 0; }
      .app-header__inner__navigation__stores {
        display: inline-block;
        vertical-align: middle;
        font-size: 0; }
        .app-header__inner__navigation__stores a {
          display: inline-block;
          margin: 0 13px 0 0; }
          .app-header__inner__navigation__stores a:last-child {
            margin-right: 0; }
      .app-header__inner__navigation__menu {
        display: block; }
        .app-header__inner__navigation__menu ul {
          padding: 0;
          margin: 25px 0;
          list-style: none;
          text-align: right;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase; }
          @media (max-width: 1599.98px) {
            .app-header__inner__navigation__menu ul {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                      justify-content: space-between; } }
          .app-header__inner__navigation__menu ul li {
            display: inline-block;
            margin: 0 55px 0 0; }
            @media (max-width: 1599.98px) {
              .app-header__inner__navigation__menu ul li {
                margin: 0;
                display: block; } }
            .app-header__inner__navigation__menu ul li:last-child {
              margin-right: 0; }
            .app-header__inner__navigation__menu ul li a {
              text-decoration: none;
              color: #000;
              -webkit-transition: 250ms all;
              transition: 250ms all; }
              .app-header__inner__navigation__menu ul li a:hover {
                color: #f7941e; }

.mobile-header {
  display: none;
  padding: 15px 35px 15px 35px;
  color: #231f20; }
  @media (max-width: 991.98px) {
    .mobile-header {
      display: block; } }
  .mobile-header img {
    max-width: 95px; }
  .mobile-header__row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .mobile-header__left {
    position: relative;
    z-index: 50; }
  .mobile-header__right {
    text-align: right;
    position: relative;
    z-index: 50; }
  .mobile-header__toggle {
    font-size: 34px;
    font-weight: 300;
    display: inline-block; }
    .mobile-header__toggle::after {
      -webkit-transition: 250ms all;
      transition: 250ms all;
      content: '\f0c9';
      line-height: 1;
      font-family: 'Font Awesome 5 Pro'; }
  .mobile-header__menu {
    background-image: url("../../assets/images/slideout-bg.png");
    background-color: #add367;
    position: absolute;
    top: 0;
    height: 100vh;
    left: 0;
    width: 100%;
    z-index: 20;
    padding-top: 180px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 250ms all;
    transition: 250ms all; }
    .mobile-header__menu ul {
      margin: 0;
      list-style-type: none;
      padding: 0 35px;
      height: 302px;
      overflow: auto; }
    .mobile-header__menu li {
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      padding: 0 0 15px 0;
      margin: 0 0 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
      .mobile-header__menu li:last-child {
        border-bottom: none; }
      .mobile-header__menu li a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase; }
  .mobile-header--active .mobile-header__menu {
    opacity: 1;
    pointer-events: all; }
  .mobile-header--active .mobile-header__toggle::after {
    content: '\f00d';
    color: #fff; }
